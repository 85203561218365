import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { useSelector } from "react-redux";
import axios from "axios";
import "./PremiumPlans.css";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import IMAGES from "../img/image";
import VerifiedIcon from "@mui/icons-material/Verified";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CheckIcon from "@mui/icons-material/Check";

const PremiumPlans = () => {
  const HowProfileWillLook = () => {
    return (
      <div className="how-profile-will-look-container">
        <h5 className="text-center">
          You will stand differently from other profiles!
        </h5>
        <div className="green-line mb-4"></div>
        <div className="new-matches-users-container premium-profile-container">
          {[1, 2, 3].map((item, index) => {
            return (
              <div className="profiles new-match-users">
                <div className="new-match-user-image">
                  {user?.photoPrivacy === "Visible to all Members" ? (
                    <img
                      src={
                        user?.images?.length > 0
                          ? user?.images[0]
                          : user?.gender === "Male"
                          ? IMAGES.male
                          : IMAGES.female
                      }
                      alt=""
                    />
                  ) : user?.photoPrivacy ===
                      "Only Visible to Premium Members" &&
                    user?.contacts > 0 ? (
                    <img
                      src={
                        user?.images.length > 0
                          ? user?.images[0]
                          : user?.gender === "Male"
                          ? IMAGES.male
                          : IMAGES.female
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        user?.gender === "Male" ? IMAGES.male : IMAGES.female
                      }
                      alt=""
                    />
                  )}
                </div>
                <div
                  className={`new-match-user-details ${
                    index === 1 && "premium"
                  }`}
                >
                  <div className="d-flex justify-content-end align-items-center">
                    {index === 1 && (
                      <span className="id-verified">
                        <VerifiedIcon className="icon" />
                        ID Verified
                      </span>
                    )}
                    {index === 1 && (
                      <span className="id-verified">
                        <WorkspacePremiumIcon className="icon" />
                        Premium
                      </span>
                    )}
                  </div>
                  <h6 className="m-0">
                    {index === 1 ? user?.username : "Other Profile"}
                  </h6>
                  <span className="text-muted">
                    <small>
                      <span>{user?.msId}</span>
                      {" | "}
                      <span>Last seen few hours ago</span>
                    </small>
                  </span>
                  <span className="user-details">
                    {user?.age} {user?.age && "Yrs"} {user?.height && " - "}
                    {user?.height?.split("-")[0]} {user?.maritalStatus && " - "}{" "}
                    {user?.maritalStatus}
                    {user?.state && " - "}
                    {user?.state} {user?.city && " - "} {user?.city}{" "}
                    {user?.qualification && " - "}
                    {user?.qualification} {user?.maslak && " - "} {user?.maslak}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const [allPlan, setAllPlan] = useState(null);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [activeUser, setActiveUser] = useState(null);
  const [hashKey, setHashKey] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [price, setPrice] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [validity, setValidity] = useState(null);

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setActiveUser(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const generateTransactionId = async () => {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 10000);
    const transactionId = `${timestamp}${random}`;
    setTransactionId(transactionId);
    return transactionId; // Return the generated transactionId
  };

  const getHashKey = (price, product, contactCount, validity) => {
    const data = {
      productinfo: product,
      firstname: activeUser?.username,
      email: activeUser?.email,
      phone: activeUser?.mobile,
      amount: price.toString(),
      contacts: contactCount.toString(),
      validity: validity,
      txnid: transactionId,
    };
    axios(
      {
        method: "POST",
        data: data,
        withCredentials: true,
        url: `api/payment/get-hashkey`,
      },
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then((res) => {
        setHashKey(res.data.hash);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get all plans
  const getAllPlan = async () => {
    try {
      const res = await axios.get("/api/admin/get-plans", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllPlan(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
    generateTransactionId();
    getAllPlan();
  }, []);

  return (
    <Layout>
      <div className="container parent-plan-container p-5">
        {!hashKey ? (
          <>
            <h5 className="text-center" style={{ lineHeight: "30px" }}>
              <b>Upgrade Now & Get upto 50% discount!</b>
            </h5>
            <div className="plans-container">
              {allPlan?.map((plan, index) => {
                return (
                  <div className={`plans-card ${index === 1 && "active"}`}>
                    <span className="name">{plan?.name}</span>
                    <p className="m-0">Access</p>
                    <h4 className="fw-bold m-0">{plan?.contacts} Contacts</h4>
                    <hr />
                    <p className="m-0 fw-bold">Services</p>
                    {plan?.features?.map((item, index) => {
                      return (
                        <>
                          <span>
                            <CheckIcon className="fs-6 me-1" />
                            <small>{item?.features}</small>
                          </span>
                          <br />
                        </>
                      );
                    })}
                    <br />
                    <h5 className="">
                      <span className="me-2">
                        <small>Price</small>
                      </span>
                      <small className="me-2 text-danger text-decoration-line-through">
                        Rs. {plan?.originalPrice}
                      </small>
                    </h5>
                    <h4 className="m-0">
                      <b> ₹ {plan?.price}</b>
                      <small>
                        <small> / {plan.validity} Months</small>
                      </small>
                    </h4>
                    <br />
                    <button
                      onClick={() => {
                        getHashKey(
                          plan?.price,
                          plan?.name,
                          plan?.contacts,
                          plan?.validity
                        ); // Pass the contacts and plan name
                        setPrice(plan?.price);
                        setContacts(plan?.contacts);
                        setProductInfo(plan?.name);
                        setValidity(plan?.validity);
                      }}
                      className="plan-btn register-btn"
                    >
                      Continue
                    </button>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="d-flex">
            <div className="plans-card text-center w-100">
              <h6>
                <>Get MyMuslimsaathi Subscription</>
              </h6>
              <form action="https://secure.payu.in/_payment" method="POST">
                <input type="hidden" name="key" value="Rlram3" />
                <input type="hidden" name="txnid" value={transactionId} />
                <input type="hidden" name="amount" value={price} />
                <input type="hidden" name="productinfo" value={productInfo} />
                <input type="hidden" name="firstname" value={user?.username} />
                <input type="hidden" name="email" value={user?.email} />
                <input type="hidden" name="phone" value={user?.mobile} />
                <input type="hidden" name="udf1" value={contacts} />
                <input type="hidden" name="udf2" value={validity} />
                <input
                  type="hidden"
                  name="surl"
                  value="https://mymuslimsaathi.com/api/payment/success"
                />
                <input
                  type="hidden"
                  name="furl"
                  value="https://mymuslimsaathi.com/api/payment/failure"
                />

                <input type="hidden" name="hash" value={hashKey} />
                {!user?.mobile ? (
                  <button
                    onClick={() => navigate("/verification")}
                    className="register-btn today-match-btn text-white"
                  >
                    Add Mobile Number
                  </button>
                ) : (
                  <button
                    disabled={hashKey === null || transactionId === null}
                    type="submit"
                    className="plan-btn text-center d-block m-auto"
                  >
                    {hashKey === null || transactionId === null ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="ms-2">Loading...</span>
                      </div>
                    ) : (
                      `Pay ${price} Now`
                    )}
                  </button>
                )}
              </form>
            </div>
          </div>
        )}
      </div>
      <HowProfileWillLook />
    </Layout>
  );
};

export default PremiumPlans;
