import React from "react";
import Layout from "../components/Layout/Layout";
import "./Terms.css";

const RefundPolicy = () => {
  return (
    <Layout>
      <div className="terms-container">
        <h4>
          <b>Refund Policy</b>
        </h4>
        <p>
          Thank you for choosing mymuslimsaathi.com as your trusted platform for
          matrimonial services. Our primary goal is to assist you in finding
          your life partner and creating meaningful connections. We understand
          that circumstances may arise that require consideration of refunds.
          Please read our refund policy carefully to understand the terms and
          conditions.
        </p>
        <h5>Cancellation Policy</h5>
        <p>
          Like many other Web sites, www.mymuslimsaathi.com do not offer
          cancellation policy for a premium membership. Once you have taken a
          membership plan, you will get all the benefits and it is not
          replaceable. So, we do not provide cancellation and hence you can use
          the benefits.
        </p>
        <h5>Refund Policy</h5>
        <p>
          At Muslimsaathi, we follow a 100% sharia-compliant refund and
          cancellation policy. When you purchase or subscribe to Mulimsaathi,
          you agree that the user contact information will be available to you
          immediately, and you acknowledge that, as a result, you waive your
          automatic statutory right of withdrawal. Therefore, you will not be
          eligible for a refund (or any alternative remedy) unless the digital
          content is defective, unavailable or doesn't perform as stated.
        </p>
        <h5>Disputes</h5>
        <p>
          www.mymuslimsaathi.com encourage you to contact our customer support
          team to try and resolve the issue. We reserve the right to modify or
          amend this refund policy at any time. Any changes will be effective
          immediately upon posting on our website.Thank you for choosing our
          Muslim matrimony services. We hope to help you find your perfect
          match.
        </p>
        <br />
        <p>
          By using mymuslimsaathi.com, you agree to abide by this refund policy.
          If you have any questions or concerns, please contact our customer
          support team at{" "}
          <a href="mailto:support@mymuslimsaathi.com">
            support@mymuslimsaathi.com
          </a>
          . Thank you for choosing mymuslimsaathi.com. We wish you success in
          finding your life partner.
        </p>
      </div>
    </Layout>
  );
};

export default RefundPolicy;
