import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import VerifiedIcon from "@mui/icons-material/Verified";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useSelector } from "react-redux";
import "./NewMatches.css";
import axios from "axios";
import { message } from "antd";
import IMAGES from "../img/image";
import FilterForm from "./components/FilterForm";
import Pagination from "./components/Pagination";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const NewMatches = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [allUser, setAllUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userContacts, setUserContacts] = useState(null);
  const [mobileFilter, setMobileFilter] = useState(null);
  const [sticky, setSticky] = useState(false);
  const [filter, setFilter] = useState({
    fromAge: "",
    toAge: "",
    fromHeight: "",
    toHeight: "",
    maritalStatus: "",
    qualification: "",
    salary: "",
    state: "",
    city: "",
    maslak: "",
  });

  // Filter function
  const applyFilter = (user) => {
    const {
      fromAge,
      toAge,
      fromHeight,
      toHeight,
      maritalStatus,
      qualification,
      state,
      city,
      maslak,
    } = filter;

    // Function to extract feet from the height format
    const extractFeet = (height) => {
      return height.split("ft")[0].trim();
    };

    return (
      (maritalStatus === "" || user.maritalStatus === maritalStatus) &&
      (fromAge === "" || user.age >= fromAge) &&
      (toAge === "" || user.age <= toAge) &&
      (fromHeight === "" || extractFeet(user.height) >= fromHeight) &&
      (toHeight === "" || extractFeet(user.height) <= toHeight) &&
      (state === "" || user.state === state) &&
      (city === "" || user.city === city) &&
      (qualification === "" || user.qualification === qualification) &&
      (maslak === "" || user.maslak === maslak)
    );
  };

  const filteredUsers = allUser?.filter(applyFilter);

  const getAllUser = async (gender) => {
    if (user?.country === null) {
      try {
        setLoading(true);
        const res = await axios.post(
          "/api/user/get-all-users",
          {
            gender: gender === "Male" ? "Female" : "Male",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          setAllUser(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const res = await axios.post(
          "/api/user/get-all-users",
          {
            gender: gender === "Male" ? "Female" : "Male",
            country: user?.country,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          setAllUser(res.data.data);
        } else {
          message.error(res.data.message);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        getAllUser(res.data.data.gender);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
    if (user) {
      setUserContacts(user?.contacts);
    }
  }, []);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;
  const totalItems = filteredUsers?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedUsers = filteredUsers?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
        setSticky(true);
      } else {
        setIsSticky(false);
        setSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout>
      <div
        className={`d-lg-none d-md-none mobile-filter-form ${
          mobileFilter && "active"
        }`}
      >
        <div className="filter-close">
          <h5 className="m-0">
            <b>Filter Users</b>
          </h5>
          <CancelIcon
            onClick={() => setMobileFilter(!mobileFilter)}
            className="icon"
          />
        </div>
        <FilterForm
          mobileFilter={mobileFilter}
          setMobileFilter={setMobileFilter}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          filter={filter}
          setFilter={setFilter}
          user={user}
        />
      </div>
      <div className="new-matches-container">
        <div
          className={`mobile-filter ${isSticky ? "sticky py-4" : ""}`}
          onClick={() => setMobileFilter(!mobileFilter)}
        >
          <span>Filter user as per your preference</span>
          <FilterListIcon />
        </div>

        <div className="d-none d-md-none d-lg-block filter-container">
          <FilterForm
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            filter={filter}
            setFilter={setFilter}
            user={user}
          />
        </div>
        <div className="all-user-container">
          {user?.isVerified === "No" ? (
            <div
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="text-center">
                <ErrorIcon className="icon text-danger" />
                <br />
                <b>
                  Your Profile is under Review for Admin Approval and it's take
                  upto 24 hours
                </b>
                <br />
                <b>You'll be notified through Email once Approved!</b>
              </span>
            </div>
          ) : loading ? (
            <div className="new-matches-users-container">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => {
                return (
                  <div
                    className={`user-loading mb-lg-2 loading ${
                      loading && "active"
                    }`}
                    onClick={() => navigate(`/profile/${user?.msId}`)}
                  ></div>
                );
              })}
            </div>
          ) : paginatedUsers?.length === 0 ? (
            <div
              style={{
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="text-center">
                <ErrorIcon className="icon text-danger" />
                <br />
                <b>Oops "No results found"</b>
                <br />
                <b>We suggest you to try with some other filter options</b>
              </span>
            </div>
          ) : (
            <div className="new-matches-users-container">
              {paginatedUsers?.map((user, index) => {
                return (
                  <div
                    key={index}
                    className="new-match-users mb-lg-2"
                    onClick={() => navigate(`/profile/${user?.msId}`)}
                  >
                    <div className="new-match-user-image">
                      {user?.photoPrivacy === "Visible to all Members" ? (
                        <img
                          src={
                            user?.images?.length > 0
                              ? `https://mymuslimsaathi.com/${user?.images[0]}`
                              : user?.gender === "Male"
                              ? IMAGES.male
                              : IMAGES.female
                          }
                          alt="user-img"
                        />
                      ) : user?.photoPrivacy ===
                          "Only Visible to Premium Members" &&
                        userContacts > 0 ? (
                        <img
                          src={
                            user?.images.length > 0
                              ? `https://mymuslimsaathi.com/${user?.images[0]}`
                              : user?.gender === "Male"
                              ? IMAGES.male
                              : IMAGES.female
                          }
                          alt="user-img"
                        />
                      ) : (
                        <img
                          src={
                            user?.gender === "Male"
                              ? IMAGES.male
                              : IMAGES.female
                          }
                          alt="user-img"
                        />
                      )}
                    </div>
                    <div className={`new-match-user-details`}>
                      <div className="d-flex mb-3 p-0">
                        {user?.idVerified === "approved" && (
                          <span className="id-verified">
                            <VerifiedUserIcon className="icon" />
                            ID Verified
                          </span>
                        )}
                        {user?.contacts > 0 && (
                          <span className="paid-member">
                            <WorkspacePremiumIcon className="icon" />
                            Paid Member
                          </span>
                        )}
                      </div>
                      {/* )} */}
                      <h6 className="m-0">{user?.username}</h6>
                      <span className="text-muted">
                        <small>
                          <small>
                            <span>{user?.msId}</span>
                          </small>
                          <span>
                            <small>
                              {user?.lastLogin !== null && " | Active "}
                              {user.lastLogin === null
                                ? ""
                                : new Date(user?.lastLogin).toDateString() ===
                                  new Date().toDateString()
                                ? `${new Date(
                                    user?.lastLogin
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}`
                                : new Date(user?.lastLogin).toDateString()}
                            </small>
                          </span>
                        </small>
                      </span>
                      <p className="user-details m-0">
                        <span>
                          {user?.age} {user?.age && "Yrs"}
                        </span>
                        <span>
                          {user?.height && " • "}
                          {user?.height?.split("-")[0]}{" "}
                        </span>
                        {user?.maritalStatus && " • "} {user?.maritalStatus}
                        {user?.state && " • "}
                        {user?.state} {user?.city && " • "} {user?.city}{" "}
                        {user?.qualification && " • "}
                        {user?.qualification}
                      </p>
                      <p className=" m-0 mt-3">
                        <small>
                          Interested in{" "}
                          {user?.gender === "Male" ? "him" : "her"}?
                        </small>
                      </p>
                      <button className="view-profile-btn">Vew Profile</button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="pagination-container">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default NewMatches;
