import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Footer/Footer.css";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { useSelector } from "react-redux";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container d-none d-md-none d-lg-block">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo">MyMuslimsaathi</div>
            <span className="text-muted">
              <small>
                Embrace love, reject dowry. MyMuslimsaathi stands as a beacon of
                change in the world of Muslim matrimony, pioneering a movement
                against dowry in India. Our platform is dedicated to fostering
                meaningful connections, where love, respect, and shared values
                are the guiding principles.
              </small>
            </span>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <ul>
              {!user && (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              )}
              {user && (
                <>
                  <li>
                    <Link to="/user-dashboard">Dashboard</Link>
                  </li>
                  <li>
                    <Link to="/premium-plans">Upgrade</Link>
                  </li>
                  <li>
                    <Link to="/ms-users">Matches</Link>
                  </li>
                </>
              )}
              <li>
                <Link to="/about">About us</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Important Pages</h6>
            <ul>
              <li>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>Connect here</h6>
            <div className="social-media-links">
              <Link
                target="_blank"
                to="https://www.instagram.com/mymuslimsaathi/"
              >
                <InstagramIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://t.me/mymuslimsaathi">
                <TelegramIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://wa.me/918983366695">
                <WhatsAppIcon className="icon" />
              </Link>
              <Link target="_blank" to="mailto:mymuslimsaathi@gmail.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <span className="text-center">
              <small>All Rights Reserved © 2024 | MYMUSLIMSAATHI</small>
            </span>
            <span className="text-center">
              <small>
                Designed & Developed by{" "}
                <Link target="_blank" to="https://aashirdigital.com/">
                  ~@aashirdigital
                </Link>
              </small>
            </span>
          </div>
        </div>
      </div>
      {!user && (
        <>
          <div className="container-fluid footer-container d-lg-none">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
                <div className="footer-logo">MyMuslimsaathi</div>
                <span className="text-muted">
                  <small>
                    Embrace love, reject dowry. MyMuslimsaathi stands as a
                    beacon of change in the world of Muslim matrimony,
                    pioneering a movement against dowry in India. Our platform
                    is dedicated to fostering meaningful connections, where
                    love, respect, and shared values are the guiding principles.
                  </small>
                </span>
              </div>
              <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
                <h6>Quick Links</h6>
                <ul>
                  {!user && (
                    <>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                      <li>
                        <Link to="/register">Register</Link>
                      </li>
                    </>
                  )}
                  {user && (
                    <>
                      <li>
                        <Link to="/user-dashboard">Dashboard</Link>
                      </li>
                      <li>
                        <Link to="/premium-plans">Upgrade</Link>
                      </li>
                      <li>
                        <Link to="/ms-users">Matches</Link>
                      </li>
                    </>
                  )}
                  <li>
                    <Link to="/about">About us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
                <h6>Important Pages</h6>
                <ul>
                  <li>
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/refund-policy">Refund Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
                <h6>Connect here</h6>
                <div className="social-media-links">
                  <Link
                    target="_blank"
                    to="https://www.instagram.com/mymuslimsaathi/"
                  >
                    <InstagramIcon className="icon" />
                  </Link>
                  <Link target="_blank" to="https://t.me/mymuslimsaathi">
                    <TelegramIcon className="icon" />
                  </Link>
                  <Link target="_blank" to="https://wa.me/918983366695">
                    <WhatsAppIcon className="icon" />
                  </Link>
                  <Link target="_blank" to="mailto:mymuslimsaathi@gmail.com">
                    <EmailIcon className="icon" />
                  </Link>
                </div>
              </div>
              <hr />
              <div className="d-flex flex-column justify-content-center">
                <span className="text-center">
                  <small>All Rights Reserved © 2024 | MYMUSLIMSAATHI</small>
                </span>
                <span className="text-center">
                  <small>
                    Designed & Developed by{" "}
                    <Link target="_blank" to="https://aashirdigital.com/">
                      ~@aashirdigital
                    </Link>
                  </small>
                </span>
              </div>
            </div>
          </div>
        </>
      )}

      {/* MOBILE FOOTER */}
      {user && (
        <div
          className={`mobile-sticky-footer d-block d-md-block- d-lg-none ${
            (location?.pathname === "/edit-profile" ||
              location?.pathname === "/partner-preferences") &&
            "d-none"
          }`}
        >
          <ul>
            <li
              className={`icon ${
                location.pathname === "/user-dashboard" && "active"
              }`}
              onClick={() => navigate("/user-dashboard")}
            >
              <div className="mobile-footer-icon">
                <HomeIcon />
              </div>
              <Link
                className={`icon ${
                  location.pathname === "/user-dashboard" && "active"
                }`}
              >
                Home
              </Link>
            </li>
            <li
              className={`icon ${
                location.pathname === "/ms-users" && "active"
              }`}
              onClick={() => navigate("/ms-users")}
            >
              <div className="mobile-footer-icon">
                <GroupIcon className="icon" />
              </div>
              <Link
                className={`icon ${
                  location.pathname === "/ms-users" && "active"
                }`}
              >
                Matches
              </Link>
            </li>
            <li
              className={`icon ${
                location.pathname === "/inbox-received" && "active"
              }`}
              onClick={() => navigate("/inbox-received")}
            >
              <div className="mobile-footer-icon">
                <EmailIcon className="icon" />
              </div>
              <Link
                className={`icon ${
                  location.pathname === "/inbox-received" && "active"
                }`}
              >
                Inbox
              </Link>
            </li>
            <li
              className={`icon ${location.pathname === "/near-me" && "active"}`}
              onClick={() => navigate("/near-me")}
            >
              <div className="mobile-footer-icon">
                <PersonPinIcon className="icon" />
              </div>
              <Link
                className={`icon ${
                  location.pathname === "/near-me" && "active"
                }`}
              >
                Near Me
              </Link>
            </li>
            <li
              className={`icon ${
                location.pathname === "/premium-plans" && "active"
              }`}
              onClick={() => navigate("/premium-plans")}
            >
              <div className="mobile-footer-icon">
                <WorkspacePremiumIcon className="icon" />
              </div>
              <Link
                className={`icon ${
                  location.pathname === "/premium-plans" && "active"
                }`}
              >
                Premium
              </Link>
            </li>
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

export default Footer;
