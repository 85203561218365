import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import IMAGES from "../../img/image";
import "./Testimonials.css";

const Testimonials = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    // autoplay: true,
    // speed: 2000,
    // autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 786,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Khushum Ahmed",
      review:
        "A fantastic matrimonial site with 24/7 service. Remarkable initiative! May Allah reward your team with immense success. Highly recommended.",
      img: `${IMAGES.female}`,
    },
    {
      name: "Mohd Zuber",
      review:
        "Impressive initiative. A highly reliable matrimonial site with 24/7 service. Found it extremely helpful. Kudos to the team!",
      img: `${IMAGES.male}`,
    },
    {
      name: "Mohammed Shakeel Salman",
      review:
        "This community is authentic, with an extraordinary team working tirelessly. Found my soulmate through mymuslimsaathi. Thanks to the polite and awesome team!",
      img: `${IMAGES.male}`,
    },
    {
      name: "Nousheen Sultana",
      review:
        "Commendable initiative by Mymuslimsaathi. Simplifies nikah, connects directly with people. Masha Allah, liked their work. Hoping for massive success. Ameen!",
      img: `${IMAGES.female}`,
    },
    {
      name: "Hannan Shareef",
      review:
        "Outstanding Muslim matrimony service with excellent management, good staff, and the best service I've seen. Highly recommended for those seeking a soulmate.",
      img: `${IMAGES.female}`,
    },
    {
      name: "Mir Sarwer Ali",
      review:
        "Assalamualaikum, thankful for a trustworthy platform. Your work is a blessing. May Allah bless you for your future progress. Instructed to avoid dowry. Highly appreciated.",
      img: `${IMAGES.male}`,
    },
  ];

  return (
    <>
      <div className="profile-slider-container">
        <div className="my-5 ">
          <h1
            className="text-center fs-1 fw-bold poppins"
            style={{ color: "#26bd68" }}
          >
            People Said About Us
          </h1>
          <div
            className="text-center mt-3"
            style={{
              width: "100px",
              height: "3px",
              backgroundColor: "#26bd68",
              margin: "auto",
            }}
          ></div>
        </div>
        <div className="profile">
          <Slider {...settings}>
            {testimonials &&
              testimonials?.map((item, index) => {
                return (
                  <div className="testimonial-container">
                    <div className="testimonial">
                      <img src={item.img} alt="" />
                      <h6>{item.name}</h6>
                      <span>
                        <small>{item.review}</small>
                      </span>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
