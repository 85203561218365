import React, { useEffect } from "react";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminSidemenu from "./AdminSidemenu";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const AdminLayout = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (user) {
      if (user.isAdmin) {
      } else {
        navigate("/user-dashboard");
      }
    }
  }, [user]);

  // useEffect(() => {
  //   if (user) {
  //     if (user?.isAdmin) {
  //       navigate("/admin-dashboard");
  //     } else {
  //       navigate("/user-dashboard");
  //     }
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="admin-layout-container">
      <AdminHeader />
      <div className="admin-body">
        <div className="admin-sidebar d-none d-md-none d-lg-block">
          <AdminSidebar />
        </div>
        <div className="admin-body-content">{children}</div>
      </div>
      <footer>
        <div className="admin-footer">
          <span>ADMIN</span>
          <span>~aashirdigital</span>
        </div>
      </footer>
    </div>
  );
};

export default AdminLayout;
