import React, { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";

const EditUserForm = ({ handleSubmit, handleChange, user }) => {
  let countryData = Country.getAllCountries();
  const [state, setState] = useState(null);
  const [cities, setCities] = useState(null);
  const [countrySelect, setCountrySelect] = useState(false);
  const [stateSelect, setStateSelect] = useState(false);
  const [citySelect, setCitySelect] = useState(false);

  // Country State City
  useEffect(() => {
    const selectedCountry = countryData.filter(
      (item) => item.name === user?.country
    );
    const stateData = State.getAllStates().filter(
      (item) => item?.countryCode === selectedCountry[0]?.isoCode
    );
    setState(stateData);
  }, [user?.country]);

  // get city
  useEffect(() => {
    const selectedState = State.getAllStates().filter(
      (item) => item.name === user?.state
    );
    const cityData = City.getAllCities().filter(
      (item) => item.stateCode === selectedState[0]?.isoCode
    );
    setCities(cityData);
  }, [user?.state]);

  // Age
  const AgeOptions = [];
  for (let i = 18; i <= 70; i++) {
    AgeOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  // Weight
  const weighOptions = [];
  for (let i = 35; i <= 100; i++) {
    weighOptions.push(<option key={i}>{i} Kg</option>);
  }
  // Height
  const heightOptions = [];
  for (let feet = 4; feet <= 7; feet++) {
    for (let inches = 0; inches <= 11; inches++) {
      if (inches !== 0) {
        const totalInches = feet * 12 + inches;
        const cm = Math.round(totalInches * 2.54);
        heightOptions.push(
          <option
            key={`${feet}-${inches}`}
            value={`${feet} ft ${inches} in - ${cm} cm`}
          >
            {feet} feet {inches} inches - {cm} cm
          </option>
        );
      } else {
        const cm = Math.round(feet * 30.48);
        heightOptions.push(
          <option key={`${feet}-${inches}`} value={`${feet} ft - ${cm} cm`}>
            {feet} feet - {cm} cm
          </option>
        );
      }
    }
  }
  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          Contacts
        </label>
        <input
          className="form-control"
          type="text"
          onChange={handleChange}
          value={user?.contacts}
          name="contacts"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          Username
        </label>
        <input
          name="username"
          className="form-control"
          type="username"
          onChange={handleChange}
          value={user?.username}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          Email
        </label>
        <input
          readOnly
          className="form-control"
          type="email"
          onChange={handleChange}
          value={user?.email}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          Is Verified?
        </label>
        <select
          onChange={handleChange}
          value={user?.isVerified}
          className="form-select"
          name="isVerified"
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          Email Verified?
        </label>
        <select
          onChange={handleChange}
          value={user?.isActive}
          className="form-select"
          name="isActive"
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          Mobile Verified?
        </label>
        <select
          onChange={handleChange}
          value={user?.mobileVerified}
          className="form-select"
          name="mobileVerified"
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          Mobile
        </label>
        <input
          type="text"
          name="mobile"
          className="form-control"
          onChange={handleChange}
          value={user?.mobile}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          ID Verified?
        </label>
        <select
          onChange={handleChange}
          value={user?.idVerified}
          className="form-select"
          name="idVerified"
        >
          <option value="">Select</option>
          <option value="approved">Approved</option>
          <option value="reject">Reject</option>
        </select>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          Is Deleted?
        </label>
        <select
          onChange={handleChange}
          value={user?.isDeleted}
          className="form-select"
          name="isDeleted"
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          Profile Created For?
        </label>
        <select
          onChange={handleChange}
          value={user?.profileCreatedFor}
          className="form-select"
          name="profileCreatedFor"
        >
          <option value="Select">Select</option>
          <option value="Myself">Myself</option>
          <option value="Son">Son</option>
          <option value="Daughter">Daughter</option>
          <option value="Brother">Brother</option>
          <option value="Sister">Sister</option>
          <option value="Friend/Relative">Friend/Relative</option>
        </select>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-3">
        <label htmlFor="Verified" className="form-label">
          Age
        </label>
        <select
          onChange={handleChange}
          value={user?.age}
          className="form-select"
          name="age"
        >
          {AgeOptions}
        </select>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Marital Status*
          </label>
          <select
            onChange={handleChange}
            value={user?.maritalStatus}
            className="form-select"
            name="maritalStatus"
          >
            <option value="Select">Select</option>
            <option value="Never Married">Never Married</option>
            <option value="Divorced">Divorced</option>
            <option value="Widowed">Widowed</option>
            <option value="Awaiting Divorce">Awaiting Divorce</option>
            <option value="Anulled">Anulled</option>
          </select>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Height*
          </label>
          <select
            value={user?.height}
            onChange={handleChange}
            className="form-select"
            name="height"
          >
            <option value="Select">Select</option>
            {heightOptions}
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Weight*
          </label>
          <select
            value={user?.weight}
            onChange={handleChange}
            className="form-select"
            name="weight"
          >
            <option value="Select">Select</option>
            {weighOptions}
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Country*
          </label>
          <input
            className="form-control"
            list="country"
            type="text"
            name="country"
            onChange={handleChange}
            value={user?.country}
          />
          <datalist name="country" id="country">
            {countryData?.map((item, index) => {
              return (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </datalist>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            State*
          </label>
          <input
            className="form-control"
            list="state"
            type="text"
            name="state"
            value={user?.state}
            onChange={handleChange}
          />
          <datalist name="state" id="state">
            {state?.map((item, i) => {
              return (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </datalist>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            City*
          </label>
          <input
            className="form-control"
            list="city"
            type="text"
            name="city"
            value={user?.city}
            onChange={handleChange}
          />
          <datalist name="city" id="city">
            {cities?.map((item, i) => {
              return (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </datalist>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Body Type*
          </label>
          <select
            value={user?.bodyType}
            onChange={handleChange}
            className="form-select"
            name="bodyType"
          >
            <option value="Select">Select</option>
            <option value="Slim">Slim</option>
            <option value="Average">Average</option>
            <option value="Athletic">Athletic</option>
            <option value="Slightly Fatty">Slightly Fatty</option>
            <option value="Fatty">Fatty</option>
            <option value="Bulky">Bulky</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Any Disability
          </label>
          <select
            value={user?.disability}
            onChange={handleChange}
            name="disability"
            className="form-select"
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Religion
          </label>
          <select
            onChange={handleChange}
            value={user?.religion}
            name="religion"
            className="form-select"
          >
            <option value="Select">Select</option>
            <option value="Islam - Muslim">Islam - Muslim</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Mother Tongue
          </label>
          <select
            value={user?.language}
            onChange={handleChange}
            name="language"
            className="form-select"
          >
            <option value="Select">Select</option>
            <option value="Hindi">Hindi</option>
            <option value="Urdu">Urdu</option>
            <option value="Arabic">Arabic</option>
            <option value="Awadhi">Awadhi</option>
            <option value="Bengali">Bengali</option>
            <option value="Gujrati">Gujrati</option>
            <option value="Harynavi">Harynavi</option>
            <option value="Kannada">Kannada</option>
            <option value="Kashmiri">Kashmiri</option>
            <option value="Khandesi">Khandesi</option>
            <option value="Malyalam">Malyalam</option>
            <option value="Manipuri">Manipuri</option>
            <option value="Marathi">Marathi</option>
            <option value="Marwari">Marwari</option>
            <option value="Rajasthani">Rajasthani</option>
            <option value="Tamil">Tamil</option>
            <option value="Telugu">Telugu</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Community
          </label>
          <select
            value={user?.community}
            onChange={handleChange}
            name="community"
            className="form-select"
          >
            <option value="Select">Select</option>
            <option value="Ansari">Ansari</option>
            <option value="Awan">Awan</option>
            <option value="Bengali">Bengali</option>
            <option value="Dawoodi Bohra">Dawoodi Bohra</option>
            <option value="Dekkani">Dekkani</option>
            <option value="Dudekula">Dudekula</option>
            <option value="Jat">Jat</option>
            <option value="Khoja">Khoja</option>
            <option value="Lebbai">Lebbai</option>
            <option value="Mapila">Mapila</option>
            <option value="Maraicar">Maraicar</option>
            <option value="Memon">Memon</option>
            <option value="Mughal">Mughal</option>
            <option value="Pathan">Pathan</option>
            <option value="Qureshi">Qureshi</option>
            <option value="Rajput">Rajput</option>
            <option value="Rowther">Rowther</option>
            <option value="Shafi">Shafi</option>
            <option value="Sheikh">Sheikh</option>
            <option value="Shia">Shia</option>
            <option value="Shia Bohra">Shia Bohra</option>
            <option value="Shia Imam Ismaili">Shia Imam Ismaili</option>
            <option value="Shia Ithna Ashariyyah">Shia Ithna Ashariyyah</option>
            <option value="Shia Zaidi">Shia Zaidi</option>
            <option value="Siddiqui">Siddiqui</option>
            <option value="Sunni">Sunni</option>
            <option value="Sunni Ehle-Hadith">Sunni Ehle-Hadith</option>
            <option value="Sunni Hanafi">Sunni Hanafi</option>
            <option value="Sunni Hunbali">Sunni Hunbali</option>
            <option value="Sunni Maliki">Sunni Maliki</option>
            <option value="Sunni Shafi">Sunni Shafi</option>
            <option value="Syed">Syed</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Maslak
          </label>
          <select
            value={user?.maslak}
            onChange={handleChange}
            name="maslak"
            className="form-select"
          >
            <option value="Select">Select</option>
            <option value="Deobandi - Jamati">Deobandi - Jamati</option>
            <option value="Sunni - Dargah - Fatiha">
              Sunni - Dargah - Fatiha
            </option>
            <option value="Sunni - Non Dargah - Fatiha">
              Sunni - Non Dargah - Fatiha
            </option>
            <option value="Salafi - Ahle hadith">Salafi - Ahle hadith</option>
            <option value="Shia - Bohri - Khoja">Shia - Bohri - Khoja</option>
            <option value="Don't want to mention">Don't want to mention</option>
          </select>
        </div>
      </div>
      {/* <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Namaaz / Salah
          </label>
          <select
            onChange={handleChange}
            value={user?.namaaz}
            name="namaaz"
            className="form-select"
          >
            <option value="Select">Select</option>
            <option value="Five Times">Five Times</option>
            <option value="Only on Jummah">Only on Jummah</option>
            <option value="During Ramadan">During Ramadan</option>
            <option value="Occasionally">Occasionally</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Zakat
          </label>
          <select
            value={user?.zakat}
            onChange={handleChange}
            name="zakat"
            className="form-select"
          >
            <option value="Select">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Fasting in Ramadan
          </label>
          <select
            value={user?.fasting}
            onChange={handleChange}
            name="fasting"
            className="form-select"
          >
            <option value="Select">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div> */}

      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Father Name
          </label>
          <input
            type="text"
            onChange={handleChange}
            value={user?.fatherName}
            name="fatherName"
            className="form-control"
          />
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Father Status
          </label>
          <select
            onChange={handleChange}
            value={user?.fatherStatus}
            name="fatherStatus"
            className="form-select"
          >
            <option value="Select">Select</option>
            <option value="Employed">Employed</option>
            <option value="Business">Business</option>
            <option value="Retired">Retired</option>
            <option value="Not Employed">Not Employed</option>
            <option value="Passed Away">Passed Away</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Mother Status
          </label>
          <select
            value={user?.motherStatus}
            onChange={handleChange}
            name="motherStatus"
            className="form-select"
          >
            <option value="Select">Select</option>
            <option value="Homemaker">Homemaker</option>
            <option value="Employed">Employed</option>
            <option value="Business">Business</option>
            <option value="Retired">Retired</option>
            <option value="Passed Away">Passed Away</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            No of Brothers
          </label>
          <input
            className="form-control"
            onChange={handleChange}
            value={user?.brothers}
            name="brothers"
            placeholder="0"
            type="number"
          />
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            No of Sisters
          </label>
          <input
            className="form-control"
            onChange={handleChange}
            value={user?.sisters}
            name="sisters"
            placeholder="0"
            type="number"
          />
        </div>
      </div>
      {/* <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            No of Brothers Married
          </label>
          <input
            className="form-control"
            onChange={handleChange}
            value={user?.brothersMarried}
            name="brothersMarried"
            placeholder="0"
            type="number"
          />
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            No of Sisters Married
          </label>
          <input
            className="form-control"
            onChange={handleChange}
            value={user?.sistersMarried}
            name="sistersMarried"
            placeholder="0"
            type="number"
          />
        </div>
      </div> */}
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Family Type
          </label>

          <select
            onChange={handleChange}
            value={user?.familyType}
            name="familyType"
            className="form-select"
          >
            <option value="Select">Select</option>
            <option value="Joint">Joint</option>
            <option value="Nuclear">Nuclear</option>
          </select>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Native Place
          </label>
          <input
            onChange={handleChange}
            value={user?.nativePlace}
            placeholder="Enter native place"
            name="nativePlace"
            type="text"
            className="form-control"
          />
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            Your Highest Qualifcation*
          </label>
          <select
            onChange={handleChange}
            value={user?.qualification}
            className="form-select"
            name="qualification"
          >
            <option value="Select">Select</option>
            <option value="SSC/10th">SSC/10th</option>
            <option value="Intermediate/12th">Intermediate/12th</option>
            <option value="Degree/Graduate">Degree/Graduate</option>
            <option value="Post Graduation">Post Graduation</option>
            <option value="Phd/Doctorate">Phd/Doctorate</option>
            <option value="Hafiz e Qur'an/Qari">Hafiz e Qur'an/Qari</option>
            <option value="Aalim-E-Deen">Aalim-E-Deen</option>
            <option value="Mufti">Mufti</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
      {user?.qualification === "Other" && (
        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
          <div className="mb-3 form-fields">
            <label className="form-label" htmlFor="">
              Your Qualification
            </label>
            <input
              placeholder="Enter your qualification"
              className="form-control"
              type="text"
              name="collegeName"
              onChange={handleChange}
              value={user?.collegeName}
            />
          </div>
        </div>
      )}
      <div className="col-12 col-sm-12 col-md-3 col-lg-3">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            You work{" "}
            {user?.workingWith === "Business/Self Employed" ? "as" : "with"}*
          </label>
          <select
            onChange={handleChange}
            value={user?.workingWith}
            className="form-select"
            name="workingWith"
          >
            <option value="Select">Select</option>
            <option value="Private Sector">Private Sector</option>
            <option value="Government Sector">Government Sector</option>
            <option value="Defense/Civil Services">
              Defense / Civil Services
            </option>
            <option value="Business/Self Employed">
              Business / Self Employed
            </option>
            <option value="Not Working">Not Working</option>
          </select>
        </div>
      </div>
      {user?.workingWith !== "Not Working" && (
        <>
          {user?.workingWith === "Business/Self Employed" ? (
            <div className="col-12 col-sm-12 col-md-3 col-lg-3">
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="">
                  Enter Your Business*
                </label>
                <input
                  type="text"
                  name="business"
                  placeholder="Enter your business"
                  className="form-control"
                  value={user?.business}
                  onChange={handleChange}
                />
              </div>
            </div>
          ) : (
            <div className="col-12 col-sm-12 col-md-3 col-lg-3">
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="">
                  Employee As*
                </label>
                <select
                  onChange={handleChange}
                  value={user?.employedAs}
                  className="form-select"
                  name="employedAs"
                >
                  <option value="Select">Select</option>
                  <option value="Banking Professional">
                    Banking Professional
                  </option>
                  <option value="Chartered Accountant">
                    Chartered Accountant
                  </option>
                  <option value="Company Secretary">Company Secretary</option>
                  <option value="Finance Professional">
                    Finance Professional
                  </option>
                  <option value="Event Manager">Event Manager</option>
                  <option value="Air Hostess">Air Hostess</option>
                  <option value="Pilot">Pilot</option>
                  <option value="Architect">Architect</option>
                  <option value="Interior Designer">Interior Designer</option>
                  <option value="Web/UX Designer">Web/UX Designer</option>
                  <option value="Artist">Artist</option>
                  <option value="Beautician">Beautician</option>
                  <option value="Fashion Designer">Fashion Designer</option>
                  <option value="Hair Stylist">Hair Stylist</option>
                  <option value="IAS/IRS/IES/IFS">IAS/IRS/IES/IFS</option>
                  <option value="Army/Navy">Army/Navy</option>
                  <option value="Lecturer">Lecturer</option>
                  <option value="Lawyer">Lawyer</option>
                  <option value="Teacher">Teacher</option>
                  <option value="Civil Engineer">Civil Engineer</option>
                  <option value="IT Profession">IT Profession</option>
                  <option value="Software Developer">Software Developer</option>
                  <option value="Doctor">Doctor</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
          )}
          <div className="col-12 col-sm-12 col-md-3 col-lg-3">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Annual{" "}
                {user?.workingWith === "Business/Self Employed"
                  ? "Turnover"
                  : "Salary"}
              </label>
              <select
                onChange={handleChange}
                value={user?.salary}
                className="form-select"
                name="salary"
              >
                <option value="Select">Select</option>
                <option value="Upto 1 Lakh">Upto 1 Lakh</option>
                <option value="1 to 2 Lakh">1 to 2 Lakh</option>
                <option value="2 to 4 Lakh">2 to 4 Lakh</option>
                <option value="4 to 7 Lakh">4 to 7 Lakh</option>
                <option value="7 to 10 Lakh">7 to 10 Lakh</option>
                <option value="10 to 15 Lakh">10 to 15 Lakh</option>
                <option value="15 to 20 Lakh">15 to 20 Lakh</option>
                <option value="20 to 40 Lakh">20 to 40 Lakh</option>
                <option value="40 to 80 Lakh">40 to 80 Lakh</option>
                <option value="1 Crore">1 Crore</option>
                <option value="More than 1 Crore">More than 1 Crore</option>
                <option value="Disclose it later">Disclose it later</option>
              </select>
            </div>
          </div>
        </>
      )}
      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
        <div className="mb-3 form-fields">
          <label className="form-label" htmlFor="">
            About
          </label>
          <textarea
            name="about"
            rows="4"
            className="form-control"
            onChange={handleChange}
            value={user && user?.about}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default EditUserForm;
