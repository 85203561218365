import React, { useEffect, useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IMAGES from "../../../img/image";
import axios from "axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/features/userSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./../../MyProfile.css";

const InboxUserCard = ({ item, hasMsId }) => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [upgardePopup, setUpgardePopup] = useState(false);
  const [contactAlert, setContactAlert] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUserAccept = async (id) => {
    try {
      const res = await axios.post(
        "/api/user/inbox-user-accept",
        {
          userOne: user?.msId,
          userTwo: id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
        getUserData();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserReject = async (id) => {
    try {
      const res = await axios.post(
        "/api/user/inbox-user-reject",
        {
          userOne: user?.msId,
          userTwo: id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
        getUserData();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleContact = () => {
    if (parseInt(user?.contacts) > 0) {
      setContactAlert(true);
    } else {
      setUpgardePopup(true);
    }
  };

  const handleSeeContact = async (id) => {
    try {
      const res = await axios.post(
        "/api/user/see-contact",
        {
          userOne: user?.msId,
          userTwo: id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
        getUserData();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div style={{ cursor: "pointer" }} className="inbox-user-card">
      <div className="inbox-user-img">
        <img
          onClick={() => navigate(`/profile/${item?.msId}`)}
          src={
            item?.images?.length > 0
              ? item?.images[0]
              : item?.gender === "Male"
              ? IMAGES.male
              : IMAGES.female
          }
          alt=""
        />
      </div>
      <div
        className="inbox-user-content"
        onClick={() => navigate(`/profile/${item?.msId}`)}
      >
        <h6>{item?.username}</h6>
        <span>
          <small>{item?.age} yrs,</small>{" "}
        </span>
        <span>
          <small>{item?.height},</small>{" "}
        </span>
        <span>
          <small>{item?.language},</small>{" "}
        </span>
        <span>
          <small>{item?.community},</small>{" "}
        </span>
        <span>
          <small>
            {item?.city}, {item?.state},
          </small>{" "}
        </span>
        <span>
          <small>
            {item?.qualification}, {item?.employedAs}
          </small>{" "}
        </span>
      </div>
      <hr className="d-block d-md-block d-lg-none" />
      {/* received */}
      {location.pathname === "/inbox-received" && (
        <div className="inbox-action-btn inbox-action-btn-received">
          <TaskAltIcon
            onClick={() => handleUserAccept(item?.msId)}
            className="icon text-success accept-icon"
          />
          <CancelIcon
            onClick={() => handleUserReject(item?.msId)}
            className="icon text-danger reject-icon"
          />
        </div>
      )}
      {/* accepted  */}
      {location.pathname === "/inbox-accepted" && (
        <>
          {hasMsId ? (
            <div className="inbox-action-btn inbox-action-btn-accepted active">
              <Link to={`tel:${hasMsId?.contactNumber}`}>
                <CallIcon target="_blank" className="icon call-btn" />
              </Link>
              <Link
                target="_blank"
                to={`https://wa.me/${hasMsId?.contactNumber}`}
              >
                <WhatsAppIcon className="icon whatsapp-btn text-success" />
              </Link>
            </div>
          ) : (
            <div className="inbox-action-btn inbox-action-btn-accepted">
              <CallIcon
                className="icon call-btn text-primary"
                onClick={handleContact}
              />
              <WhatsAppIcon
                className="icon whatsapp-btn text-success"
                onClick={handleContact}
              />
              {parseInt(user?.contacts) <= 0 && (
                <button
                  className="inbox-upgrade-btn"
                  onClick={() => navigate("/premium-plans")}
                >
                  Upgrade
                </button>
              )}
            </div>
          )}
        </>
      )}
      {/* sent  */}
      {location.pathname === "/inbox-sent" && (
        <div
          className="inbox-action-btn inbox-action-btn-sent text-center"
          style={{ backgroundColor: "#ccffe2" }}
        >
          <PunchClockIcon />
          <small>Waiting for approval</small>
        </div>
      )}
      {/* deleted  */}
      {location.pathname === "/inbox-deleted" && (
        <div
          className="inbox-action-btn inbox-action-btn-deleted"
          style={{ backgroundColor: "#f2e1dc" }}
        >
          <CancelIcon className="icon text-danger" />
          <small>Request Declined</small>
        </div>
      )}
      {/* Contact Upgrade Modal */}
      <div
        onClick={() => setUpgardePopup(!upgardePopup)}
        className={`contact-upgrade-modal ${upgardePopup && "active"}`}
      >
        <div className="upgrade-container">
          <span>
            <small>To Contact directly Upgrade Now</small>
          </span>
          <button onClick={() => navigate("/premium-plans")}>
            Upgrade Now
          </button>
        </div>
      </div>
      <div
        onClick={() => setContactAlert(!contactAlert)}
        className={`contact-upgrade-modal ${contactAlert && "active"}`}
      >
        <div className="upgrade-container">
          <span className="text-center">
            <small>Are you sure you want to see the Contact?</small>
          </span>
          <div className="d-flex">
            <button
              className="px-4"
              onClick={() => handleSeeContact(item?.msId)}
            >
              Yes
            </button>
            <button
              className="px-4"
              onClick={() => setContactAlert(!contactAlert)}
            >
              No
            </button>
          </div>
          <span className="text-center">
            <small>
              After clicking on yes remaining Contacts will be{" "}
              {parseInt(user?.contacts) - 1}
            </small>
          </span>
        </div>
      </div>
    </div>
  );
};

export default InboxUserCard;
