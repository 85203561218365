import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setUser } from "../redux/features/userSlice";
import Cta from "../components/Home/Cta";
import Faq from "../components/Home/Faq";
import HowItWorks from "../components/Home/HowItWorks";
import ProfileSlider from "../components/Home/ProfileSlider";
import Testimonials from "../components/Home/Testimonials";
import Features from "../components/Home/Features";
import Blogs from "../components/Blogs";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
          console.log("Token has expired");
          localStorage.removeItem("token");
          navigate("/login");
        }
      } catch (error) {
        console.error("Error decoding or checking JWT token:", error);
      }
    } else {
      console.log("No token found in local storage");
    }
  };

  useEffect(() => {
    checkTokenExpiration();
  }, []);

  return (
    <Layout>
      <HeroSection />
      <ProfileSlider />
      <HowItWorks />
      <Testimonials />
      <Features />
      <Cta />
      <Blogs />
      <Faq />
      {/* <CashReward /> */}
      {/* <AboutUs /> */}
    </Layout>
  );
};

export default Home;
