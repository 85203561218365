import React, { useState, useEffect } from "react";
import { Country, State, City } from "country-state-city";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSlider from "react-slider";

const FilterForm = ({
  user,
  filter,
  setFilter,
  setMobileFilter,
  mobileFilter,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  let countryData = Country.getAllCountries();
  const [state, setState] = useState(null);
  const [cities, setCities] = useState(null);

  const isFilterActive =
    filter.fromAge ||
    filter.toAge ||
    filter.maritalStatus ||
    filter.qualification ||
    filter.fromHeight ||
    filter.toHeight ||
    filter.maslak ||
    filter.salary ||
    filter.state ||
    filter.city;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });

    if (name === "age") {
    }
  };
  // Age
  const AgeOptions = [];
  for (let i = 18; i <= 70; i++) {
    AgeOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  // Height
  const heightOptions = [];
  for (let feet = 4; feet <= 7; feet++) {
    for (let inches = 0; inches <= 11; inches++) {
      if (inches !== 0) {
        const totalInches = feet * 12 + inches;
        const cm = Math.round(totalInches * 2.54);
        heightOptions.push(
          <option
            key={`${feet}-${inches}`}
            value={`${feet} ft ${inches} in - ${cm} cm`}
          >
            {feet} feet {inches} inches - {cm} cm
          </option>
        );
      } else {
        const cm = Math.round(feet * 30.48);
        heightOptions.push(
          <option key={`${feet}-${inches}`} value={`${feet} ft - ${cm} cm`}>
            {feet} feet - {cm} cm
          </option>
        );
      }
    }
  }
  // Country State City
  useEffect(() => {
    const selectedCountry = countryData.filter(
      (item) => item.name === user?.country
    );
    const stateData = State.getAllStates().filter(
      (item) => item?.countryCode === selectedCountry[0]?.isoCode
    );
    setState(stateData);
  }, [filter.country, user?.country]);

  // get city
  useEffect(() => {
    const selectedState = State.getAllStates().filter(
      (item) => item.name === filter?.state
    );
    const cityData = City.getAllCities().filter(
      (item) => item.stateCode === selectedState[0]?.isoCode
    );
    setCities(cityData);
  }, [filter.state]);

  return (
    <div className="filter-form">
      <form className="m-0" onSubmit={handleSubmit}>
        {/* Marital Status  */}
        <div className="form-fields-filter mb-3">
          <div className="marital-status">
            <h5 className="m-0">
              <b>Marital Status</b>
            </h5>
            <span
              onClick={() =>
                setFilter({
                  ...filter,
                  maritalStatus: "Never Married",
                })
              }
              className={`${
                filter?.maritalStatus === "Never Married" && "active"
              }`}
            >
              Never Married
            </span>
            <span
              onClick={() =>
                setFilter({
                  ...filter,
                  maritalStatus: "Second Marriage",
                })
              }
              className={`${
                filter?.maritalStatus === "Second Marriage" && "active"
              }`}
            >
              Second Marriage
            </span>
            <span
              onClick={() =>
                setFilter({ ...filter, maritalStatus: "Divorced" })
              }
              className={`${filter?.maritalStatus === "Divorced" && "active"}`}
            >
              Divorced
            </span>
            <span
              onClick={() => setFilter({ ...filter, maritalStatus: "Khula" })}
              className={`${filter?.maritalStatus === "Khula" && "active"}`}
            >
              Khula
            </span>
            <span
              onClick={() => setFilter({ ...filter, maritalStatus: "Widowed" })}
              className={`${filter?.maritalStatus === "Widowed" && "active"}`}
            >
              Widowed
            </span>
            <span
              onClick={() => setFilter({ ...filter, maritalStatus: "Widower" })}
              className={`${filter?.maritalStatus === "Widower" && "active"}`}
            >
              Widower
            </span>
          </div>
        </div>
        {/* AGE  */}
        <div className="mb-2">
          <h5 className="m-0">
            <b>Select Age</b>
          </h5>
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            min={18}
            max={70}
            defaultValue={[18, 70]}
            ariaLabel={["Lower thumb", "Upper thumb"]}
            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
            renderThumb={(props, state) => (
              <div {...props}>{state.valueNow}</div>
            )}
            pearling
            minDistance={5}
            onChange={(value, index) =>
              setFilter({ ...filter, fromAge: value[0], toAge: value[1] })
            }
          />
        </div>
        {/* HEIGHT  */}
        <div className="mb-2">
          <h5 className="m-0">
            <b>Select Height (ft)</b>
          </h5>
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            min={4}
            max={8}
            defaultValue={[4, 8]}
            ariaLabel={["Lower thumb", "Upper thumb"]}
            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
            renderThumb={(props, state) => (
              <div {...props}>{state.valueNow}</div>
            )}
            pearling
            minDistance={1}
            onChange={(value, index) =>
              setFilter({ ...filter, fromHeight: value[0], toHeight: value[1] })
            }
          />
        </div>
        {/* MASLAK  */}
        <div className="form-fields-filter mb-3">
          <select
            onChange={handleChange}
            value={filter?.maslak}
            className="form-select"
            name="maslak"
          >
            <option value="Select">Select Maslak</option>
            <option value="Deobandi - Jamati">Deobandi - Jamati</option>
            <option value="Sunni - Dargah - Fatiha">
              Sunni - Dargah - Fatiha
            </option>
            <option value="Sunni - Non Dargah - Fatiha">
              Sunni - Non Dargah - Fatiha
            </option>
            <option value="Salafi - Ahle hadith">Salafi - Ahle hadith</option>
            <option value="Shia - Bohri - Khoja">Shia - Bohri - Khoja</option>
            <option value="Don't want to mention">Don't want to mention</option>
          </select>
        </div>
        {/* EDUCATION  */}
        <div className="form-fields-filter mb-3">
          <div className="marital-status">
            <h5 className="m-0">
              <b>Education</b>
              {/* {filter?.qualification !== "" && (
                <p
                  className="d-inline m-0 ms-2"
                  onClick={() => setFilter({ ...filter, qualification: "" })}
                >
                  (remove)
                </p>
              )} */}
            </h5>
            <span
              className={`${filter.qualification === "SSC/10th" && "active"}`}
              onClick={() =>
                setFilter({ ...filter, qualification: "SSC/10th" })
              }
            >
              SSC/10th
            </span>
            <span
              className={`${
                filter.qualification === "Intermediate/12th" && "active"
              }`}
              onClick={() =>
                setFilter({ ...filter, qualification: "Intermediate/12th" })
              }
            >
              Intermediate/12th
            </span>
            <span
              className={`${
                filter.qualification === "Degree/Graduate" && "active"
              }`}
              onClick={() =>
                setFilter({ ...filter, qualification: "Degree/Graduate" })
              }
            >
              Degree/Graduate
            </span>
            <span
              className={`${
                filter.qualification === "Post Graduation" && "active"
              }`}
              onClick={() =>
                setFilter({ ...filter, qualification: "Post Graduation" })
              }
            >
              Post Graduation
            </span>
            <span
              className={`${
                filter.qualification === "Phd/Doctorate" && "active"
              }`}
              onClick={() =>
                setFilter({ ...filter, qualification: "Phd/Doctorate" })
              }
            >
              Phd/Doctorate
            </span>
            <span
              className={`${
                filter.qualification === "Hafiz e Qur'an/Qari" && "active"
              }`}
              onClick={() =>
                setFilter({ ...filter, qualification: "Hafiz e Qur'an/Qari" })
              }
            >
              Hafiz e Qur'an/Qari
            </span>
            <span
              className={`${
                filter.qualification === "Aalim-E-Deen" && "active"
              }`}
              onClick={() =>
                setFilter({ ...filter, qualification: "Aalim-E-Deen" })
              }
            >
              Aalim-E-Deen
            </span>
            <span
              className={`${filter.qualification === "Mufti" && "active"}`}
              onClick={() => setFilter({ ...filter, qualification: "Mufti" })}
            >
              Mufti
            </span>
            <span
              className={`${filter.qualification === "Other" && "active"}`}
              onClick={() => setFilter({ ...filter, qualification: "Other" })}
            >
              Other
            </span>
          </div>
        </div>
        {/* STATE CITY  */}
        {location?.pathname !== "/near-me" && (
          <>
            <div className="form-fields-filter mb-3">
              <select
                onChange={handleChange}
                value={filter?.state}
                className="form-select"
                name="state"
              >
                <option value="">Select State</option>
                {state &&
                  state?.map((item, index) => {
                    return (
                      <option key={index} value={item?.name}>
                        {item?.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="form-fields-filter mb-3">
              <select
                onChange={handleChange}
                value={filter?.city}
                className="form-select"
                name="city"
              >
                <option value="">Select City</option>
                {cities &&
                  cities?.map((item) => {
                    return <option value={item?.name}>{item?.name}</option>;
                  })}
              </select>
            </div>
          </>
        )}

        <button
          onClick={() => setMobileFilter(!mobileFilter)}
          className="d-lg-none new-match-filter-btn me-2"
        >
          Search
        </button>
        {isFilterActive && (
          <button
            className="new-match-filter-btn bg-danger"
            onClick={() => {
              setFilter({
                fromAge: "",
                toAge: "",
                fromHeight: "",
                toHeight: "",
                maritalStatus: "",
                qualification: "",
                state: "",
                city: "",
                salary: "",
                state: "",
                city: "",
                maslak: "",
              });
            }}
          >
            Clear Filter
          </button>
        )}
      </form>
    </div>
  );
};

export default FilterForm;
