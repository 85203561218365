import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./MyPhotos.css";
import { setUser } from "../redux/features/userSlice";
import { message } from "antd";
import IMAGES from "../img/image";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import WifiLoader from "../components/Loader/WifiLoader";
import imageCompression from "browser-image-compression";

const MyPhotos = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [photoTab, setPhotoTab] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userImages, setUserImages] = useState(null);
  const [photoPrivacy, setPhotoPrivacy] = useState(user?.photoPrivacy);
  const [loading, setLoading] = useState(false);

  const handleFileChange = async (e) => {
    const files = e.target.files;
    if (files.length > 5) {
      alert("You can only upload up to 5 files.");
      e.target.value = null;
      return;
    }
    const compressedImages = await Promise.all(
      Array.from(files).map(async (file) => {
        const compressedImage = await compressImage(file);
        return compressedImage;
      })
    );
    setSelectedFiles(compressedImages);
  };

  const compressImage = async (file) => {
    try {
      const options = {
        maxSizeMB: 1, // Adjust the maximum size as needed
        maxWidthOrHeight: 800, // Adjust the maximum width or height as needed
      };
      const compressedImage = await imageCompression(file, options);
      return compressedImage;
    } catch (error) {
      console.error("Error compressing image:", error);
      return file;
    }
  };

  // upload
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("email", user?.email);

    const filesArray = Array.from(selectedFiles);
    if (selectedFiles.length === 0) {
      message.error("No photo is selected");
      return;
    }

    for (let i = 0; i < filesArray.length; i++) {
      formData.append("images", filesArray[i]);
    }
    setLoading(true);
    try {
      const res = await axios.post("/api/image/upload-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        dispatch(setUser(res.data.data));
        getUserData();
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  // delete
  const handleDelete = async (imagePath) => {
    try {
      setLoading(true);
      const res = await axios.delete("/api/image/delete-image", {
        data: { email: user?.email, imagePath },
      });
      if (res.data.success) {
        setUserImages((prevImages) =>
          prevImages.filter((image) => image !== imagePath)
        );
        dispatch(setUser(res.data.data));
        getUserData();
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error deleting image:", error);
    }
  };

  // photo privacy update
  const handleUpdatePhotoPrivacy = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/update-photo-privacy", {
        email: user?.email,
        photoPrivacy,
      });
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
        getUserData();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setUserImages(res.data.data.images);
        setPhotoPrivacy(res.data.data.photoPrivacy);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <div className="my-photos-container">
        <div className="my-photos-tab-btn-container">
          <button
            onClick={() => setPhotoTab(0)}
            className={`photo-tab-btn me-2 ${photoTab === 0 && "active"}`}
          >
            Photos
          </button>
          <button
            onClick={() => setPhotoTab(1)}
            className={`photo-tab-btn ${photoTab === 1 && "active"}`}
          >
            Privacy Settings
          </button>
        </div>
        <hr />
        {photoTab === 0 ? (
          <>
            {user?.images?.length < 5 && (
              <div className="image-upload-container">
                <h6>Upload photos from your computer</h6>
                <input
                  aria-label="Select Image"
                  className="form-control"
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp"
                  name="image"
                  required
                  multiple
                  onChange={handleFileChange}
                />
                {loading ? (
                  <>
                    <button
                      style={{ backgroundColor: "#066759" }}
                      className="register-btn mt-2"
                    >
                      Uploading
                      <span
                        className="spinner-grow spinner-grow-sm ms-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </>
                ) : (
                  <button
                    style={{ backgroundColor: "#066759" }}
                    className="register-btn mt-2"
                    onClick={handleUpload}
                  >
                    Upload
                  </button>
                )}
                <p className="m-0 p-0 mt-2">
                  <small>
                    Note: You can upload 5 photos to your profile. Each photos
                    must be less than 2 MB and in jpg, jpeg, or png format. All
                    photos uploaded are screened as per Photo Guidelines and 98%
                    of those get activated within 2 hours.
                  </small>
                </p>
              </div>
            )}

            <div className="photos">
              {loading ? (
                <WifiLoader />
              ) : (
                userImages?.map((img, index) => {
                  return (
                    <div className="photo-image-holder" key={index}>
                      <img src={img} alt="loading.." />
                      <DeleteIcon
                        className="delete-icon"
                        onClick={() => handleDelete(img)}
                      />
                    </div>
                  );
                })
              )}
            </div>
            <div className="other-ways text-center mt-5">
              <h6>Other ways to upload your photos</h6>
              <p>
                <small>E-mail your photos to photos@muslimsaathi.in</small>
              </p>
            </div>
            <hr />
            <div className="photo-guidance text-center">
              <div className="row">
                <div className="mb-4 col-12 col-sm-12 col-mg-6 col-lg-6">
                  <div>
                    <DoneAllIcon style={{ color: "green" }} />
                    <span className="ms-2">Photos you can upload</span>
                  </div>
                  <img className="me-3" src={IMAGES.photoview1} alt="" />
                  <img src={IMAGES.photoview2} alt="" />
                </div>
                <div className="col-12 col-sm-12 col-mg-6 col-lg-6">
                  <div>
                    <CloseIcon style={{ color: "red" }} />
                    <span className="ms-2">Photos you cannot upload</span>
                  </div>
                  <img className="me-3" src={IMAGES.photoview3} alt="" />
                  <img className="me-3" src={IMAGES.photoview4} alt="" />
                  <img className="me-3" src={IMAGES.photoview5} alt="" />
                  <img className="me-3" src={IMAGES.photoview6} alt="" />
                </div>
              </div>
            </div>
            {/* <div className="mt-5 text-center">
              <span>
                <Link to="/photo-guidelines">Photo Guidelines</Link> |{" "}
                <Link to="/photo-faq">Photo FAQ</Link>
              </span>
            </div> */}
          </>
        ) : (
          <div className="image-upload-container photo-privacy-settings">
            <h6>Set a privacy to your photos</h6>
            <div className="row">
              <div className="col-12-col-sm-12 col-md-6 col-lg-6">
                <span
                  className={`${
                    photoPrivacy === "Visible to all Members" && "active"
                  }`}
                  onClick={() => setPhotoPrivacy("Visible to all Members")}
                >
                  Visible to all Members
                </span>
                <span
                  className={`${
                    photoPrivacy === "Only Visible to Premium Members" &&
                    "active"
                  }`}
                  onClick={() =>
                    setPhotoPrivacy("Only Visible to Premium Members")
                  }
                >
                  Only Visible to Premium Members
                </span>
                <button
                  style={{ backgroundColor: "#066759" }}
                  className="register-btn mt-2"
                  onClick={handleUpdatePhotoPrivacy}
                >
                  Save Settings
                </button>
                {/* <p className="m-0 p-0 mt-2">
                  <small>
                    Note: You can upload 5 photos to your profile. Each photos
                    must be less than 2 MB and in jpg, jpeg, or png format. All
                    photos uploaded are screened as per Photo Guidelines and 98%
                    of those get activated within 2 hours.
                  </small>
                </p> */}
              </div>
              <div className="privacy-photo-demo col-12-col-sm-12 col-md-6 col-lg-6">
                <p className="m-0">
                  <small>
                    {photoPrivacy === "Visible to all Members"
                      ? "This is how your photos will look like to all"
                      : "This is how your photos will look like to Non Premium users"}
                  </small>
                </p>
                {photoPrivacy === "Visible to all Members" ? (
                  <img
                    className="mt-2"
                    src={
                      user?.images?.length > 0
                        ? user?.images[0]
                        : user?.gender === "Male"
                        ? IMAGES.male
                        : IMAGES.female
                    }
                    alt=""
                  />
                ) : (
                  <img
                    className="mt-2"
                    src={user?.gender === "Male" ? IMAGES.male : IMAGES.female}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default MyPhotos;
