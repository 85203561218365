import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const BasicInfo = ({ handleChange, activeUser, setActiveUser }) => {
  // Weight
  const weighOptions = [];
  for (let i = 35; i <= 100; i++) {
    weighOptions.push(<option key={i}>{i} Kg</option>);
  }
  // Height
  const heightOptions = [];
  for (let feet = 4; feet <= 7; feet++) {
    for (let inches = 0; inches <= 11; inches++) {
      if (inches !== 0) {
        const totalInches = feet * 12 + inches;
        const cm = Math.round(totalInches * 2.54);
        heightOptions.push(
          <option
            key={`${feet}-${inches}`}
            value={`${feet} ft ${inches} in - ${cm} cm`}
          >
            {feet} feet {inches} inches - {cm} cm
          </option>
        );
      } else {
        const cm = Math.round(feet * 30.48);
        heightOptions.push(
          <option key={`${feet}-${inches}`} value={`${feet} ft - ${cm} cm`}>
            {feet} feet - {cm} cm
          </option>
        );
      }
    }
  }
  // Age
  const AgeOptions = [];
  for (let i = 18; i <= 70; i++) {
    AgeOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  const [field, setField] = useState(true);

  return (
    <div className="basic-info">
      <div className="title">
        <div className="d-flex justify-content-between">
          <span>Basic Info & Lifestyle</span>
          <span>
            {field ? (
              <RemoveIcon onClick={() => setField(false)} />
            ) : (
              <AddIcon onClick={() => setField(true)} />
            )}
          </span>
        </div>
        <hr className="m-0 p-0 mt-2 mb-3" />
        <div className={`row fields-container ${field ? "" : "off"}`}>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Profile Created For
              </label>
              <select
                onChange={(e) =>
                  handleChange("profileCreatedFor", e.target.value)
                }
                value={activeUser?.profileCreatedFor}
                name="profileCreatedFor"
                className="form-select"
              >
                <option value="Myself">Myself</option>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Friend">Friend</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Age
              </label>
              <select
                value={activeUser?.age}
                onChange={(e) => handleChange("age", e.target.value)}
                name="age"
                className="form-select"
              >
                <option value="">Select</option>
                {AgeOptions}
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Marital Status
              </label>
              <select
                value={activeUser?.maritalStatus}
                onChange={(e) => handleChange("maritalStatus", e.target.value)}
                name="maritalStatus"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Never Married">Never Married</option>
                <option value="Second Marriage">Second Marriage</option>
                <option value="Divorced">Divorced</option>
                <option value="Khula">Khula</option>
                <option value="Widowed">Widowed</option>
                <option value="Widower">Widower</option>
              </select>
            </div>
          </div>
          {activeUser?.maritalStatus !== "Never Married" && (
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="">
                  Any Childrens?
                </label>
                <select
                  value={activeUser?.childrens}
                  onChange={(e) => handleChange("childrens", e.target.value)}
                  name="childrens"
                  className="form-select"
                >
                  <option value="Select">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
          )}
          {activeUser?.maritalStatus !== "Single (Never Married)" &&
            activeUser?.childrens === "yes" && (
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    How many childrens?
                  </label>
                  <select
                    value={activeUser?.childrensCount}
                    onChange={(e) =>
                      handleChange("childrensCount", e.target.value)
                    }
                    name="childrensCount"
                    className="form-select"
                  >
                    <option value="Select">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
            )}
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Height
              </label>
              <select
                value={activeUser?.height}
                onChange={(e) => handleChange("height", e.target.value)}
                name="height"
                className="form-select"
              >
                {activeUser?.height === null && <option>Select</option>}
                {heightOptions}
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Weight
              </label>
              <select
                value={activeUser?.weight}
                onChange={(e) => handleChange("weight", e.target.value)}
                name="weight"
                className="form-select"
              >
                <option value="Select">Select</option>
                {weighOptions}
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Body Type*
              </label>
              <select
                value={activeUser?.bodyType}
                onChange={(e) => handleChange("bodyType", e.target.value)}
                name="bodyType"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Slim">Slim</option>
                <option value="Average">Average</option>
                <option value="Athletic">Athletic</option>
                <option value="Slightly Fatty">Slightly Fatty</option>
                <option value="Fatty">Fatty</option>
                <option value="Bulky">Bulky</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Any Disability
              </label>
              <select
                value={activeUser?.disability}
                onChange={(e) => handleChange("disability", e.target.value)}
                name="disability"
                className="form-select"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Interest/Hobbies*
              </label>
              <input
                type="text"
                name="diet"
                onChange={(e) => handleChange("diet", e.target.value)}
                value={activeUser?.diet}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 form-fields">
              <label className="form-label" htmlFor="">
                Complexion*
              </label>
              <select
                value={activeUser?.complexion}
                onChange={(e) => handleChange("complexion", e.target.value)}
                name="complexion"
                className="form-select"
              >
                <option value="Select">Select</option>
                <option value="Very Fair">Very Fair</option>
                <option value="Fair">Fair</option>
                <option value="Wheatish">Wheatish</option>
                <option value="Brown">Brown</option>
                <option value="Black">Black</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
