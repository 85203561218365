import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import "./AdminUsers.css";
import IMAGES from "../img/image";
import { Link, useNavigate, useParams } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import BadgeIcon from "@mui/icons-material/Badge";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";

const AdminUsers = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [allUser, setAllUser] = useState(null);
  const [mail, setMail] = useState(false);
  const [email, setEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [msg, setMsg] = useState(null);
  const [searchMsId, setSearchMsId] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [searchVerified, setSearchVerified] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [originalUserData, setOriginalUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  //! delete user
  const handleDeleteUser = async (id) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (shouldDelete) {
      try {
        const res = await axios.post(
          "/api/admin/delete-user",
          { id },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          message.success(res.data.message);
          getAllUser();
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  //! Search
  const handleSearch = () => {
    if (originalUserData) {
      const filteredUsers = originalUserData.filter((user) => {
        const idMatch =
          user.msId && user.msId.toLocaleLowerCase().includes(searchMsId);
        const usernameMatch =
          user.username &&
          user.username.toLocaleLowerCase().includes(searchUsername);
        const emailMatch =
          user.email && user.email.toLocaleLowerCase().includes(searchEmail);
        const mobileMatch = user.mobile && user.mobile.includes(searchMobile);
        const genderMatch =
          selectedFilter === "Male" || selectedFilter === "Female"
            ? user?.gender?.toLowerCase() === selectedFilter.toLowerCase()
            : true;
        const verificationMatch =
          selectedFilter === "Verified" || selectedFilter === "Non Verified"
            ? user.isVerified === (selectedFilter === "Verified" ? "Yes" : "No")
            : true;
        const premiumMember =
          selectedFilter === "Premium Users"
            ? user.contacts > 0
            : selectedFilter === "Non Premium Users"
            ? user.contacts <= 0
            : true;
        const verifiedUsers =
          selectedFilter === "Verification"
            ? user.isVerifiedCount > 1 && user?.isVerified === "No"
            : true;
        const idProofUsers =
          selectedFilter === "ID Proof"
            ? user.idProof !== null &&
              (user?.idVerified === "No" || user?.idVerified === "reject")
            : true;

        return (
          idMatch &&
          usernameMatch &&
          emailMatch &&
          mobileMatch &&
          genderMatch &&
          verificationMatch &&
          premiumMember &&
          verifiedUsers &&
          idProofUsers
        );
      });

      setAllUser(filteredUsers);
    }
  };

  //! Mail Send
  const handleSendMail = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "/api/admin/send-mail-to-user",
        {
          email,
          subject,
          msg,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setMail(false);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get all users
  const getAllUser = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-all-users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUser(res.data.data);
        setOriginalUserData(res.data.data);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUser();
    if (params.type) {
      setSelectedFilter(params.type);
    }
  }, [params.type]);

  useEffect(() => {
    handleSearch();
  }, [
    searchMsId,
    searchUsername,
    searchEmail,
    searchMobile,
    selectedFilter,
    originalUserData,
  ]);

  function handleWhatsapp(mobile) {
    const whatsappLink = `https://wa.me/${mobile}`;
    window.open(whatsappLink, "_blank");
  }

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className={`mail-container ${mail && "active"}`}>
          <div className="form-fields">
            <p>Sending mail to: {email}</p>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Enter Subject"
              name="subject"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
            <textarea
              onChange={(e) => setMsg(e.target.value)}
              value={msg}
              placeholder="Enter message"
              className="form-control"
              name="msg"
              cols="30"
              rows="5"
            ></textarea>
            <button onClick={handleSendMail} className="register-btn mt-3">
              Send Message
            </button>
            <button
              onClick={() => setMail(false)}
              className="bg-danger register-btn mt-3 ms-2"
            >
              Go Back
            </button>
          </div>
        </div>
        <div className="page-title">
          <h3 className="m-0">Users</h3>
          {/* <button>Add New User</button> */}
        </div>
        <hr />
        <div className="table-container">
          <div className="tools">
            <input
              type="search"
              name="msId"
              placeholder="Search by ID"
              value={searchMsId}
              onChange={(e) => setSearchMsId(e.target.value)}
            />
            <input
              type="search"
              name="username"
              placeholder="Search by Username"
              value={searchUsername}
              onChange={(e) => setSearchUsername(e.target.value)}
            />
            <input
              type="search"
              name="email"
              placeholder="Search by Email"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
            />
            <input
              type="search"
              name="mobile"
              placeholder="Search by Mobile"
              value={searchMobile}
              onChange={(e) => setSearchMobile(e.target.value)}
            />
            <select
              name="type"
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Verified">Verified</option>
              <option value="Non Verified">Non Verified</option>
              <option value="Non Premium Users">Non Premium Users</option>
              <option value="Premium Users">Premium Users</option>
              <option value="Verification">Re Verification</option>
              <option value="ID Proof">Id Proof Verification</option>
            </select>
          </div>
          {loading ? (
            <div className="loading-container">
              <div class="loader">
                <span class="loader-text">loading</span>
                <span class="load"></span>
              </div>
            </div>
          ) : (
            <table className="table user-table">
              <thead>
                <tr>
                  <th>Avatar</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Created At</th>
                  <th>Verified</th>
                  <th>Id Proof</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allUser &&
                  allUser?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            src={
                              user?.images?.length > 0
                                ? `https://mymuslimsaathi.com/${user?.images[0]}`
                                : user?.gender === "Male"
                                ? IMAGES.male
                                : IMAGES.female
                            }
                            alt=""
                          />
                        </td>
                        <td>
                          <small>{user?.username}</small>
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          <small
                            onClick={() => {
                              setMail(true);
                              setEmail(user?.email);
                            }}
                          >
                            {user?.email}
                          </small>
                        </td>
                        <td onClick={() => handleWhatsapp(user?.mobile)}>
                          <small style={{ cursor: "pointer" }}>
                            {user?.mobile}
                          </small>
                        </td>
                        <td>
                          <small>
                            {new Date(user?.createdAt).toLocaleString(
                              "default",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </small>
                        </td>
                        <td align="center">
                          <small>
                            {user?.isVerified === "Yes" ? (
                              <DoneIcon className="text-success" />
                            ) : (
                              <>
                                <CloseIcon className="fs-5 me-3" />
                              </>
                            )}
                          </small>
                        </td>
                        <td>
                          {user?.idProof !== null ? (
                            <BadgeIcon className="text-success" />
                          ) : (
                            <NoAccountsIcon className="text-danger" />
                          )}
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <EditIcon
                              onClick={() =>
                                navigate(`/admin-edit-user/${user?.msId}`)
                              }
                              className="me-2 text-muted"
                            />
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDeleteUser(user?.msId)}
                              className="text-danger"
                            />
                            <Link
                              className="text-success"
                              target="_blank"
                              to={`http://localhost:3000/profile-pdf/${user?.msId}`}
                            >
                              <PictureAsPdfIcon />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminUsers;
