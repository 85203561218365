import React, { useEffect, useState } from "react";
import CompleteProfileLayout from "../components/Layout/CompleteProfileLayout";
import "./CompleteProfile.css";
import { message } from "antd";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import MobileVerify from "../user/components/MobileVerify/MobileVerify";

const CompleteProfile = () => {
  const navigate = useNavigate();
  let countryData = Country.getAllCountries();
  const [state, setState] = useState(null);
  const [cities, setCities] = useState(null);
  const [countrySelect, setCountrySelect] = useState(false);
  const [stateSelect, setStateSelect] = useState(false);
  const [citySelect, setCitySelect] = useState(false);

  const [formTab, setFormTab] = useState(0);
  const [form, setForm] = useState({
    //step1
    age: "",
    maritalStatus: "",
    height: "",
    bodyType: "",
    qualification: "",
    workingWith: "",
    business: "",
    employedAs: "",
    salary: "",
    //step2
    fatherName: "",
    fatherStatus: "",
    motherStatus: "",
    brothers: "",
    sisters: "",
    maslak: "",
    //step3
    country: "India",
    state: "",
    city: "",
  });

  const [formError, setFormError] = useState(false);

  // get country
  useEffect(() => {
    const selectedCountry = countryData.filter(
      (item) => item.name === form.country
    );
    const stateData = State.getAllStates().filter(
      (item) => item?.countryCode === selectedCountry[0]?.isoCode
    );
    setState(stateData);
  }, [form.country]);

  // get city
  useEffect(() => {
    const selectedState = State.getAllStates().filter(
      (item) => item.name === form?.state
    );
    const cityData = City.getAllCities().filter(
      (item) => item.stateCode === selectedState[0]?.isoCode
    );
    setCities(cityData);
  }, [form.state]);

  // Weight
  const weighOptions = [];
  for (let i = 35; i <= 100; i++) {
    weighOptions.push(<option key={i}>{i} Kg</option>);
  }
  // Height
  const heightOptions = [];
  for (let feet = 4; feet <= 7; feet++) {
    for (let inches = 0; inches <= 11; inches++) {
      if (inches !== 0) {
        const totalInches = feet * 12 + inches;
        const cm = Math.round(totalInches * 2.54);
        heightOptions.push(
          <option
            key={`${feet}-${inches}`}
            value={`${feet} ft ${inches} in - ${cm} cm`}
          >
            {feet} feet {inches} inches - {cm} cm
          </option>
        );
      } else {
        const cm = Math.round(feet * 30.48);
        heightOptions.push(
          <option key={`${feet}-${inches}`} value={`${feet} ft - ${cm} cm`}>
            {feet} feet - {cm} cm
          </option>
        );
      }
    }
  }
  // Age
  const AgeOptions = [];
  for (let i = 18; i <= 70; i++) {
    AgeOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  // brother/sister
  const brotherOptions = [];
  for (let i = 0; i <= 10; i++) {
    brotherOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  //stepone
  const handleStepOneSubmit = async (e) => {
    e.preventDefault();
    if (form?.workingWith === "Not Working") {
      if (form?.qualification === "" || form?.workingWith === "") {
        return setFormError(true);
      }
    }
    if (form?.workingWith !== "Not Working") {
      if (form?.workingWith === "Business/Self Employed") {
        if (
          form.age === "" ||
          form.maritalStatus === "" ||
          form.height === "" ||
          form.bodyType === "" ||
          form?.qualification === "" ||
          form?.workingWith === "" ||
          form?.business === "" ||
          form?.salary === ""
        ) {
          return setFormError(true);
        }
      }
      if (form?.workingWith !== "Business/Self Employed") {
        if (
          form.age === "" ||
          form.maritalStatus === "" ||
          form.height === "" ||
          form.bodyType === "" ||
          form?.qualification === "" ||
          form?.workingWith === "" ||
          form?.employedAs === "" ||
          form?.salary === ""
        ) {
          return setFormError(true);
        }
      }
    }
    try {
      const res = await axios.post("/api/user/step-one", {
        email: localStorage.getItem("email"),
        form,
      });
      if (res.data.success) {
        message.success(res.data.message);
        setFormError(false);
        setFormTab(2);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //steptwo
  const handleStepTwoSubmit = async (e) => {
    e.preventDefault();
    if (
      form?.fatherName === "" ||
      form?.fatherStatus === "" ||
      form?.motherStatus === "" ||
      form?.brothers === "" ||
      form?.sisters === "" ||
      form?.maslak === "" ||
      form?.country === "" ||
      form?.state === "" ||
      form?.city === ""
    ) {
      setFormError(true);
      return;
    }
    try {
      const partnerAgeFrom =
        form?.age === 18 ? 18 : form?.age - 5 < 18 ? 18 : form?.age - 5;
      const res = await axios.post("/api/user/step-two", {
        email: localStorage.getItem("email"),
        partnerAgeFrom: partnerAgeFrom,
        partnerAgeTo: form?.age,
        partnerReligion: form?.religion,
        partnerLanguage: form?.language,
        partnerMaritalStatus: form?.maritalStatus,
        partnerCountry: form?.country,
        partnerState: form?.state,
        partnerCity: form?.city,
        form,
      });
      if (res.data.success) {
        e.preventDefault();
        const msg = "Hey! Your Email Verification OTP is";
        const email = localStorage.getItem("email");
        try {
          const res = await axios.post("/api/user/send-otp", { email, msg });
          if (res.data.success) {
            message.success(res.data.message);
          } else {
            message.error(res.data.message);
          }
        } catch (error) {
          console.log(error);
        }
        // message.success(res.data.message);
        setFormError(false);
        localStorage.removeItem("email");
        localStorage.setItem("token", res.data.data);
        navigate("/user-dashboard");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (form.country !== "") {
      if (
        Country.getAllCountries().find((item) => item.name === form.country)
      ) {
        setCountrySelect(false);
      } else {
        setCountrySelect(true);
      }
    }
    if (form.state !== "") {
      if (state.find((item) => item.name === form.state)) {
        setStateSelect(false);
      } else {
        setStateSelect(true);
      }
    }
    if (form.city !== "") {
      if (cities.find((item) => item.name === form.city)) {
        setCitySelect(false);
      } else {
        setCitySelect(true);
      }
    }
  }, [form.country, form.state, form.city]);

  useEffect(() => {
    if (!localStorage.getItem("email")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [formTab]);

  return (
    <CompleteProfileLayout>
      <div className="container-fluid complete-profile-container">
        {/* stepOne */}
        {formTab === 0 && (
          <MobileVerify formTab={formTab} setFormTab={setFormTab} />
        )}
        {formTab === 1 && (
          <form className="complete-profile-form">
            <div className="row">
              <h4 className="text-center text-muted">
                <small>Let's Create your profile now</small>
              </h4>
              <div className="text-center">
                <div className="green-line mb-4"></div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Age*
                  </label>
                  <select
                    onChange={handleChange}
                    value={form?.age}
                    className="form-select"
                    name="age"
                  >
                    <option value="Select">Select</option>
                    {AgeOptions}
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError && form?.age === "" && "Select Age"}
                    </small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Marital Status*
                  </label>
                  <select
                    onChange={handleChange}
                    value={form?.maritalStatus}
                    className="form-select"
                    name="maritalStatus"
                  >
                    <option value="Select">Select</option>
                    <option value="Never Married">Never Married</option>
                    <option value="Second Marriage">Second Marriage</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Khula">Khula</option>
                    <option value="Widowed">Widowed</option>
                    <option value="Widower">Widower</option>
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError &&
                        form?.maritalStatus === "" &&
                        "Select Marital Status"}
                    </small>
                  </span>
                </div>
              </div>
              {(form?.maritalStatus === "Second Marriage" ||
                form?.maritalStatus === "Divorced" ||
                form?.maritalStatus === "Khula" ||
                form?.maritalStatus === "Widowed" ||
                form?.maritalStatus === "Widower") && (
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="">
                      Any Childrens?
                    </label>
                    <select
                      value={form?.childrens}
                      onChange={handleChange}
                      name="childrens"
                      className="form-select"
                    >
                      <option value="Select">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
              )}
              {form?.maritalStatus !== "Single (Never Married)" &&
                form?.childrens === "yes" && (
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="mb-3 form-fields">
                      <label className="form-label" htmlFor="">
                        How many childrens?
                      </label>
                      <select
                        value={form?.childrensCount}
                        onChange={handleChange}
                        name="childrensCount"
                        className="form-select"
                      >
                        <option value="Select">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>
                )}
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Height*
                  </label>
                  <select
                    onChange={handleChange}
                    value={form?.height}
                    className="form-select"
                    name="height"
                  >
                    <option value="Select">Select</option>
                    {heightOptions}
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError && form?.height === "" && "Select Height"}
                    </small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Body Type*
                  </label>
                  <select
                    onChange={handleChange}
                    value={form?.bodyType}
                    className="form-select"
                    name="bodyType"
                  >
                    <option value="Select">Select</option>
                    <option value="Slim">Slim</option>
                    <option value="Average">Average</option>
                    <option value="Athletic">Athletic</option>
                    <option value="Slightly Fatty">Slightly Fatty</option>
                    <option value="Fatty">Fatty</option>
                    <option value="Bulky">Bulky</option>
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError && form?.bodyType === "" && "Select Body Type"}
                    </small>
                  </span>
                </div>
              </div>

              <h4 className="text-center text-muted mt-4">
                <small>
                  Just a few questions about your education & career
                </small>
              </h4>
              <div className="text-center">
                <div className="green-line mb-4"></div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Your Highest Qualifcation*
                  </label>
                  <select
                    onChange={handleChange}
                    value={form?.qualification}
                    className="form-select"
                    name="qualification"
                  >
                    <option value="Select">Select</option>
                    <option value="SSC/10th">SSC/10th</option>
                    <option value="Intermediate/12th">Intermediate/12th</option>
                    <option value="Degree/Graduate">Degree/Graduate</option>
                    <option value="Post Graduation">Post Graduation</option>
                    <option value="Phd/Doctorate">Phd/Doctorate</option>
                    <option value="Hafiz e Qur'an/Qari">
                      Hafiz e Qur'an/Qari
                    </option>
                    <option value="Aalim-E-Deen">Aalim-E-Deen</option>
                    <option value="Mufti">Mufti</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError &&
                        form?.qualification === "" &&
                        "Select Highest Qualification"}
                    </small>
                  </span>
                </div>
              </div>
              {form?.qualification === "Other" && (
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="">
                      Your Qualification
                    </label>
                    <input
                      placeholder="Enter your qualification"
                      className="form-control"
                      type="text"
                      name="collegeName"
                      onChange={handleChange}
                      value={form?.collegeName}
                    />
                    <span className="text-danger">
                      <small>
                        {formError &&
                          form?.collegeName === "" &&
                          "Enter College Name"}
                      </small>
                    </span>
                  </div>
                </div>
              )}
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Your Profession*
                  </label>
                  <select
                    onChange={handleChange}
                    value={form?.workingWith}
                    className="form-select"
                    name="workingWith"
                  >
                    <option value="Select">Select</option>
                    <option value="Private Sector">Private Sector</option>
                    <option value="Government Sector">Government Sector</option>
                    <option value="Defense/Civil Services">
                      Defense / Civil Services
                    </option>
                    <option value="Business/Self Employed">
                      Business / Self Employed
                    </option>
                    <option value="Not Working">Not Working</option>
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError &&
                        form?.workingWith === "" &&
                        "Select Working with"}
                    </small>
                  </span>
                </div>
              </div>
              {form?.workingWith !== "Not Working" && (
                <>
                  {form?.workingWith === "Business/Self Employed" ? (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="mb-3 form-fields">
                        <label className="form-label" htmlFor="">
                          Enter Your Business*
                        </label>
                        <input
                          type="text"
                          name="business"
                          placeholder="Enter your business"
                          className="form-control"
                          onChange={handleChange}
                        />
                      </div>
                      <span className="text-danger m-0">
                        <small>
                          {formError &&
                            form?.business === "" &&
                            "Enter your business"}
                        </small>
                      </span>
                    </div>
                  ) : (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="mb-3 form-fields">
                        <label className="form-label" htmlFor="">
                          Employee As*
                        </label>
                        <select
                          onChange={handleChange}
                          value={form?.employedAs}
                          className="form-select"
                          name="employedAs"
                        >
                          <option value="Select">Select</option>
                          <option value="Banking Professional">
                            Banking Professional
                          </option>
                          <option value="Chartered Accountant">
                            Chartered Accountant
                          </option>
                          <option value="Company Secretary">
                            Company Secretary
                          </option>
                          <option value="Finance Professional">
                            Finance Professional
                          </option>
                          <option value="Event Manager">Event Manager</option>
                          <option value="Air Hostess">Air Hostess</option>
                          <option value="Pilot">Pilot</option>
                          <option value="Architect">Architect</option>
                          <option value="Interior Designer">
                            Interior Designer
                          </option>
                          <option value="Web/UX Designer">
                            Web/UX Designer
                          </option>
                          <option value="Artist">Artist</option>
                          <option value="Beautician">Beautician</option>
                          <option value="Fashion Designer">
                            Fashion Designer
                          </option>
                          <option value="Hair Stylist">Hair Stylist</option>
                          <option value="IAS/IRS/IES/IFS">
                            IAS/IRS/IES/IFS
                          </option>
                          <option value="Army/Navy">Army/Navy</option>
                          <option value="Lecturer">Lecturer</option>
                          <option value="Lawyer">Lawyer</option>
                          <option value="Teacher">Teacher</option>
                          <option value="Civil Engineer">Civil Engineer</option>
                          <option value="IT Profession">IT Profession</option>
                          <option value="Software Developer">
                            Software Developer
                          </option>
                          <option value="Doctor">Doctor</option>
                          <option value="Other">Other</option>
                        </select>
                        <span className="text-danger">
                          <small>
                            {formError &&
                              form?.employedAs === "" &&
                              "Select Employed As"}
                          </small>
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="mb-3 form-fields">
                      <label className="form-label" htmlFor="">
                        Annual{" "}
                        {form?.workingWith === "Business/Self Employed"
                          ? "Turnover"
                          : "Salary"}
                      </label>
                      <select
                        onChange={handleChange}
                        value={form?.salary}
                        className="form-select"
                        name="salary"
                      >
                        <option value="Select">Select</option>
                        <option value="Upto 1 Lakh">Upto 1 Lakh</option>
                        <option value="1 to 2 Lakh">1 to 2 Lakh</option>
                        <option value="2 to 4 Lakh">2 to 4 Lakh</option>
                        <option value="4 to 7 Lakh">4 to 7 Lakh</option>
                        <option value="7 to 10 Lakh">7 to 10 Lakh</option>
                        <option value="10 to 15 Lakh">10 to 15 Lakh</option>
                        <option value="15 to 20 Lakh">15 to 20 Lakh</option>
                        <option value="20 to 40 Lakh">20 to 40 Lakh</option>
                        <option value="40 to 80 Lakh">40 to 80 Lakh</option>
                        <option value="1 Crore">1 Crore</option>
                        <option value="More than 1 Crore">
                          More than 1 Crore
                        </option>
                        <option value="Disclose it later">
                          Disclose it later
                        </option>
                      </select>
                      <span className="text-danger">
                        <small>
                          {formError &&
                            form?.salary === "" &&
                            "Select Annaul Salary"}
                        </small>
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <button
                className="continue-btn"
                type="submit"
                onClick={handleStepOneSubmit}
              >
                Next
              </button>
            </div>
          </form>
        )}
        {/* stepTwo */}
        {formTab === 2 && (
          <form
            className="complete-profile-form"
            onSubmit={handleStepTwoSubmit}
          >
            <div className="row">
              <h4 className="text-center text-muted">
                <small>Family Details?</small>
              </h4>
              <div className="text-center">
                <div className="green-line mb-4"></div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Father Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={form?.fatherName}
                    name="fatherName"
                    placeholder="Enter father name"
                  />
                  <span className="text-danger">
                    <small>
                      {formError &&
                        form?.fatherName === "" &&
                        "Enter father name"}
                    </small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Father Status*
                  </label>
                  <select
                    onChange={handleChange}
                    value={form?.fatherStatus}
                    name="fatherStatus"
                    className="form-select"
                  >
                    <option value="Select">Select</option>
                    <option value="Employed">Employed</option>
                    <option value="Business">Business</option>
                    <option value="Retired">Retired</option>
                    <option value="Not Employed">Not Employed</option>
                    <option value="Passed Away">Passed Away</option>
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError &&
                        form?.fatherStatus === "" &&
                        "Select father status"}
                    </small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Mother Status*
                  </label>
                  <select
                    value={form?.motherStatus}
                    onChange={handleChange}
                    name="motherStatus"
                    className="form-select"
                  >
                    <option value="Select">Select</option>
                    <option value="Homemaker">Homemaker</option>
                    <option value="Employed">Employed</option>
                    <option value="Business">Business</option>
                    <option value="Retired">Retired</option>
                    <option value="Passed Away">Passed Away</option>
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError &&
                        form?.motherStatus === "" &&
                        "Select mother status"}
                    </small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    No. of Brothers*
                  </label>
                  <select
                    value={form?.brothers}
                    onChange={handleChange}
                    name="brothers"
                    className="form-select"
                  >
                    <option value="Select">Select</option>
                    {brotherOptions}
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError &&
                        form?.brothers === "" &&
                        "Select no. of brothers"}
                    </small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    No. of Sisters*
                  </label>
                  <select
                    value={form?.sisters}
                    onChange={handleChange}
                    name="sisters"
                    className="form-select"
                  >
                    <option value="Select">Select</option>
                    {brotherOptions}
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError &&
                        form?.sisters === "" &&
                        "Select no. of sisters"}
                    </small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Maslak
                  </label>
                  <select
                    value={form?.maslak}
                    onChange={handleChange}
                    name="maslak"
                    className="form-select"
                  >
                    <option value="Select">Select</option>
                    <option value="Deobandi - Jamati">Deobandi - Jamati</option>
                    <option value="Sunni - Dargah - Fatiha">
                      Sunni - Dargah - Fatiha
                    </option>
                    <option value="Sunni - Non Dargah - Fatiha">
                      Sunni - Non Dargah - Fatiha
                    </option>
                    <option value="Salafi - Ahle hadith">
                      Salafi - Ahle hadith
                    </option>
                    <option value="Shia - Bohri - Khoja">
                      Shia - Bohri - Khoja
                    </option>
                    <option value="Don't want to mention">
                      Don't want to mention
                    </option>
                  </select>
                  <span className="text-danger">
                    <small>
                      {formError && form?.maslak === "" && "Select maslak"}
                    </small>
                  </span>
                </div>
              </div>
              <h4 className="text-center text-muted mt-4">
                <small>Where do you stay?</small>
              </h4>
              <div className="text-center">
                <div className="green-line mb-4"></div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Country*
                  </label>
                  <input
                    className="form-control"
                    list="country"
                    type="text"
                    name="country"
                    onChange={handleChange}
                    value={form.country}
                  />
                  <datalist name="country" id="country">
                    {countryData?.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </datalist>
                  <span className="text-danger">
                    <small>
                      {countrySelect
                        ? "Select country from list"
                        : formError && form.country === "" && "Select Country"}
                    </small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    State*
                  </label>
                  <input
                    className="form-control"
                    list="state"
                    type="text"
                    name="state"
                    onChange={handleChange}
                  />
                  <datalist name="state" id="state">
                    {state?.map((item, i) => {
                      return (
                        <option key={i} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </datalist>
                  <span className="text-danger">
                    <small>
                      {stateSelect
                        ? "Select state from list"
                        : formError && form.state === "" && "Select state"}
                    </small>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    City*
                  </label>
                  <input
                    className="form-control"
                    list="city"
                    type="text"
                    name="city"
                    onChange={handleChange}
                  />
                  <datalist name="city" id="city">
                    {cities?.map((item, i) => {
                      return (
                        <option key={i} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </datalist>
                  <span className="text-danger">
                    <small>
                      {citySelect
                        ? "Select city from list"
                        : formError && form.city === "" && "Select city"}
                    </small>
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="continue-btn"
                type="submit"
                onClick={() => setFormTab(0)}
              >
                Prev
              </button>
              <button className="continue-btn">Next</button>
            </div>
          </form>
        )}
      </div>
    </CompleteProfileLayout>
  );
};

export default CompleteProfile;
