import React from "react";
import "./Terms.css";
import Layout from "../components/Layout/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="terms-container">
        <h4>
          <b>Privacy Policy</b>
        </h4>
        <p>
          www.mymuslimsaathi.com is an electronic website targeting to provide
          premium matrimonial alliance services. We ensure to protect the
          privacy of the users as per our terms and conditions. Once you have
          registered to our website as a user/member, you accept our full terms
          and conditions and must provide us with the mandatory information. You
          may or may not provide information that is marked as not mandatory.
          The users are solely responsible for maintaining the confidentiality
          of the User Name and User Password and other activities related to
          transmission/transactions using their own user identity/name. They
          shall be solely responsible for carrying out any online or off-line
          transactions that involves credit/debit cards or any other forms of
          device or documents for making such transactions.
          www.mymuslimsaathi.com accepts no accountability / liability of your
          act and your improper use of information relating to credit/debit
          cards usage whether online/offline. www.mymuslimsaathi.com is securely
          connected to service partners, which includes servers/administrators.
          Your IP address and other information including Email address, Contact
          name, User name and password, Address, Pin code, Contact number or
          other contact details etc; may be used for the general administration
          of the Website. Your IP address may also be used for the purpose of
          gathering broad demographic information. The information will solely
          be used by us to inform you and deliver information that might be of
          your interests. This information may include banner advertisements,
          administrative notifications, product hand-outs, and communications
          which are relevant for you where using the web site is concerned. Your
          acceptance to our terms and condition and privacy policy specify that
          you are willing to receive such information. Information gathered from
          the users will not be shared, sell, rent, trade or give away or share
          with any third party without the consent of the user. A member/user
          who wish to contact with Builders, Agents/Brokers or any individual
          who have shared their contact information for classified ads,
          promotions and advertisement on our portal can do so at their request
          through us. The privacy policy may be changed from time to time
          without any prior notice. It is at your disposal to review our privacy
          policy periodically, for any changes made to improve the service of
          the website. www.mymuslimsaathi.com does not claim any responsibility
          for any errors or inconsistencies. But we are committed to ensure that
          the information provided to you is precise and accurate.
          www.mymuslimsaathi.com disclaims any and all responsibility or
          liability for the accuracy, content, completeness, legitimacy,
          consistency, or operability or accessibility of information or matter
          advertised on this web site by third parties. www.mymuslimsaathi.com,
          respects the privacy of our users. This document outlines the types of
          personal information that is received and collected by
          www.mymuslimsaathi.com and its usage.
        </p>
        <h5>Log Files</h5>
        <p>
          Like many other Web sites, www.mymuslimsaathi.com uses log files that
          includes information such as IP addresses, type of browser used,
          Internet Service Provider (ISP), date/time, exit pages, frequency of
          clicks to evaluate trends, manage the site, track user’s movement in
          the site, and collect demographic information. IP addresses and other
          related information are not linked to any information that might
          identify an individual personally.
        </p>
        <h5>Cookies and Web Beacons</h5>
        <p>
          www.mymuslimsaathi.com use cookies to store information about
          visitor’s preferences, record user-specific information such as pages
          the user accessed or visited, customize Web page content based on
          user’s browser type or other information that the user sends via their
          browser. Contact : info@mymuslimsaathi.com for further clarifications.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
